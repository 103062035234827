
import { mapGetters, mapState } from 'vuex'
import ProductFunctions from '~/mixins/ProductFunctions'
import BookmarkProductModalButton from '~/components/modals/buttons/BookmarkProductModalButton'
import InfoIcon from '~/components/icons/InfoIcon.vue'

export default {
  components: {
    InfoIcon,
    BookmarkProductModalButton,
  },

  mixins: [ProductFunctions],
  inject: ['page'],

  props: {
    data: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      selectedAmount: 1,
      selectedFormatId: this.data.product?.id || this.page?.id,
      couponPlatformConfigs: [],
    }
  },

  async fetch() {
    try {
      const [couponPlatformConfigs] = await Promise.all([
        this.$store.dispatch('products/getCouponPlatforms'),
        this.$store.dispatch('global/loadDefaultProductComponentsSettings'),
      ])

      this.couponPlatformConfigs = couponPlatformConfigs
    } catch (error) {
      this.$sentry.captureException(error)

      throw error
    }
  },

  computed: {
    ...mapState('cart', ['cart']),
    ...mapGetters('cart', ['productsInCart']),
    product() {
      return this.formats.find((format) => format.id === this.selectedFormatId)
    },

    initialProduct() {
      if (this.data.product?.id) {
        return this.data.product
      }

      return this.page
    },

    formats() {
      let additionalFormats = this.initialProduct.purchasableFormats?.map(
        (relation) => relation.product
      )

      if (!additionalFormats) {
        additionalFormats = []
      }

      return [this.initialProduct, ...additionalFormats]
    },

    canBeBoughtInShop() {
      return (
        this.product.buyable_in_shop &&
        !(this.productInCartAlready && this.productIsLicenseProduct)
      )
    },

    productInCartAlready() {
      return (
        this.productsInCart.some((item) => item.id === this.product.id) ?? false
      )
    },

    productIsLicenseProduct() {
      return this.product.is_license_product
    },

    canOnlyBeBoughtExternally() {
      return ['E-Book', 'App'].includes(this.product.product_type)
    },

    calcPrice() {
      if (this.selectedAmount < 1) {
        return 0
      }
      return this.selectedAmount * this.product.price
    },

    externalLinks() {
      if (!this.product.isbn) {
        return []
      }

      if (
        !Array.isArray(
          this.$store.state.global.defaultProductComponentsSettings
            ?.configure_and_buy_ebook_links
        )
      ) {
        return []
      }

      return this.$store.state.global.defaultProductComponentsSettings.configure_and_buy_ebook_links
        .map((link) => ({
          ...link,
          link: link.url
            ?.replace(/\{isbn\}/i, this.product.isbn)
            .replace(/\{isbnNumbers\}/i, this.product.isbn_numbers),
          type: 'website',
        }))
        .filter((link) => link.link)
    },

    publicationYear() {
      return this.product.publication_month?.substring(0, 4)
    },

    couponPlatforms() {
      if (!this.couponPlatformConfigs) return []

      return this.product.coupon_platforms
        ?.map((platform) => this.couponPlatformConfigs[platform])
        .filter((platform) => platform)
    },
  },

  methods: {
    handleAmountProducts(amount) {
      if (amount < 1) {
        this.selectedAmount = 1
      } else {
        this.selectedAmount = amount
      }
    },
  },
}
