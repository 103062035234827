
import debounce from 'lodash/debounce'
import { useId } from '~/utils/useId'

export default {
  props: {
    value: {
      type: Number,
      required: true,
    },

    disableDecrementToZero: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      id: `quantity-input-${useId()}`,
      internalValue: 0,
    }
  },

  watch: {
    value(value) {
      this.internalValue = value
    },

    internalValue(input, old) {
      if (!old) {
        return
      }

      if (input !== old) {
        this.processInput(input)
      }
    },
  },

  created() {
    this.internalValue = this.value
  },

  methods: {
    handleChange(newValue) {
      if (newValue >= 0) {
        this.$emit('input', newValue)
      }
    },

    increment() {
      const newValue = this.value + 1
      this.internalValue = newValue
      this.handleChange(newValue)
    },

    decrement() {
      if (this.disableDecrementToZero && this.value <= 1) {
        return
      }

      const newValue = this.value - 1
      this.internalValue = newValue
      this.handleChange(newValue)
    },

    processInput: debounce(function (input) {
      if (input === '') {
        return
      }

      if (this.disableDecrementToZero && input <= 0) {
        input = 1
      }

      if (input < 0) {
        input = 0
      }

      this.internalValue = input

      this.handleChange(input)
    }, 300),
  },
}
