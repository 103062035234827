import { render, staticRenderFns } from "./ConfigureAndBuy.vue?vue&type=template&id=571e4b38&scoped=true&"
import script from "./ConfigureAndBuy.vue?vue&type=script&lang=js&"
export * from "./ConfigureAndBuy.vue?vue&type=script&lang=js&"
import style0 from "./ConfigureAndBuy.vue?vue&type=style&index=0&id=571e4b38&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "571e4b38",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppImage: require('/vercel/path0/components/general/AppImage.vue').default,HepLogo: require('/vercel/path0/components/general/HepLogo.vue').default,InfoIcon: require('/vercel/path0/components/icons/InfoIcon.vue').default,RotatedPin: require('/vercel/path0/components/icons/RotatedPin.vue').default,ProductPrice: require('/vercel/path0/components/product/ProductPrice.vue').default,ProductFormatSelector: require('/vercel/path0/components/product/ProductFormatSelector.vue').default,QuantityInput: require('/vercel/path0/components/cart/QuantityInput.vue').default,AddToCart: require('/vercel/path0/components/shop/AddToCart.vue').default,PageLink: require('/vercel/path0/components/cards/PageLink.vue').default,AppStore: require('/vercel/path0/components/icons/AppStore.vue').default,AppLink: require('/vercel/path0/components/general/AppLink.vue').default,GooglePlay: require('/vercel/path0/components/icons/GooglePlay.vue').default,Container: require('/vercel/path0/components/general/Container.vue').default,SelectableBgColor: require('/vercel/path0/components/general/SelectableBgColor.vue').default})
