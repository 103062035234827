
export default {
  name: 'InfoIcon',
  props: {
    color: {
      type: String,
      required: false,
      default: 'bg-teal-500',
    },
  },
}
