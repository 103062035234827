
import { mapActions } from 'vuex'

export default {
  props: {
    itemId: {
      type: Number,
      required: true,
    },

    shoppingListCode: {
      type: String,
      default: null,
    },

    amount: {
      type: Number,
      default: 1,
    },

    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      state: 'idle',
      innerDisabled: false,
    }
  },

  watch: {
    disabled(value) {
      this.innerDisabled = value
    },
  },

  created() {
    this.innerDisabled = this.disabled
  },

  methods: {
    ...mapActions('cart', ['increaseAmount']),

    async doAddToCart() {
      try {
        this.state = 'adding'

        await this.increaseAmount({
          itemId: this.itemId,
          amount: this.amount,
          shoppingListCode: this.shoppingListCode,
        })

        this.$toasted.success(this.$t('product-added-to-cart'), {
          duration: 5000,
          action: {
            text: this.$t('goto-cart'),
            onClick: (e, toastObject) => {
              this.$router.push({
                path: '/shop/cart',
              })
              toastObject.goAway(0)
            },
          },
        })
      } catch (error) {
        if (
          error.response?.status === 422 &&
          error.response?.data?.code === 'purchase_only_individually'
        ) {
          this.innerDisabled = true
          this.$toasted.error(
            this.$t('product-can-only-be-purchased-individually'),
            {
              duration: 5000,
              action: {
                text: this.$t('goto-cart'),
                onClick: (e, toastObject) => {
                  this.$router.push({
                    path: '/shop/cart',
                  })
                  toastObject.goAway(0)
                },
              },
            }
          )
        } else {
          this.$sentry.captureException(error)

          this.$toasted.error(this.$t('error-while-adding-products'))
        }
      } finally {
        this.state = 'idle'
      }
    },
  },
}
